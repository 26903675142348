import { render } from "react-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useParams,
} from "react-router-dom";


import Login from "./components/ui/Login";
import { AuthProvider } from "./contexts/AuthContext";
import PrivateRoute from "./components/ui/PrivateRoute";
import App from "./App";
import History from "./components/history/History";
import Homepage from "./components/ui/Homepage";


const rootElement = document.getElementById("root");

render(
  <BrowserRouter>
    <AuthProvider>
      <Routes>
       

        <Route path="/" element={<App />}>
          <Route path="login" element={<Login />}></Route>

          <Route path="/" element={<Navigate replace to="/home" />} />







          <Route
            path="/history"
            element={
              <PrivateRoute>
                <History />
              </PrivateRoute>
            }
          />

          <Route
            path="/home"
            element={
              <PrivateRoute>
                          <br />
                          <br />
                          <br />
                          <br />

                <Homepage />
              </PrivateRoute>
            }
          />



          <Route
            path="*"
            element={
              <main style={{ padding: "1rem" }}>
                <p>Invalid path</p>
              </main>
            }
          />
        </Route>
      </Routes>
    </AuthProvider>
  </BrowserRouter>,
  rootElement
);
