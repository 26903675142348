import * as React from 'react';
import {  TextField, Grid, Button, Stack } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import HistoryIcon from '@mui/icons-material/History';
import { SelectCampaign } from "../datafetcher/DatafetcherFeatures";
import { subMonths } from 'date-fns';
import { v4 as uuidv4 } from "uuid";
import { now } from "../utils/utils";
import { useAuth } from "../../contexts/AuthContext";
import usePostRequest from "../utils/requests";
import { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";



const sncfValidationSchema = yup.object({
  campaign_name: yup.string().required('* Le nom de la campagne est requis'),
  date_debut_campagne: yup.date().required('* La date de début est requise').typeError("* La date de début est requise").nullable(),
  date_fin_campagne: yup
    .date()
    .required('* La date de fin est requise')
    .typeError("* La date de fin est requise")
    .nullable()
    .min(yup.ref('date_debut_campagne'), 'La date de fin doit être après la date de début'),
});

function Homepage() {
  const { currentUser } = useAuth();
  const [startDate, setStartDate] = React.useState<Date | null>(null);
  const [endDate, setEndDate] = React.useState<Date | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const runPostRequest = usePostRequest();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
  initialValues: {
    campaign_name: "",
    date_debut_campagne: null,
    date_fin_campagne: null,
  },
  validationSchema: sncfValidationSchema,
  // validationSchema: sncfValidationSchema, // Ajouter le schéma de validation ici
  onSubmit: async (value: any) => {
    console.log('onSubmit')
    setIsSubmitting(true);

    // Vérifier les erreurs de validation avant de continuer
    const errors = await formik.validateForm();
    formik.setTouched({
      campaign_name: true,
      date_debut_campagne: true,
      date_fin_campagne: true,
    });
    console.log('Object.keys(errors)',Object.keys(errors))

    if (Object.keys(errors).length > 0) {
      // Afficher un message d'erreur si des champs requis ne sont pas remplis
      Object.values(errors).forEach(error => {
        enqueueSnackbar(error, { variant: 'error' });
      });
      setIsSubmitting(false);
      return;
    }

    const formattedStartDate = value.date_debut_campagne
      ? new Date(value.date_debut_campagne).toISOString().split('T')[0]
      : null;
    const formattedEndDate = value.date_fin_campagne
      ? new Date(value.date_fin_campagne).toISOString().split('T')[0]
      : null;

    // Récupérer les crédits restants depuis le backend
    let remainingCredits;
    try {
      const idToken = await currentUser.getIdToken(true);
      const response = await fetch("/getCredits", {
        method: "GET",
        headers: {
          Authorization: idToken,
          "Content-Type": "application/json",
        },
      });
      remainingCredits = await response.json();
    } catch (error) {
      console.error("Erreur lors de la récupération des crédits :", error);
      enqueueSnackbar("Erreur lors de la récupération des crédits", { variant: 'error' });
      setIsSubmitting(false);
      return;
    }
    console.log('remainingCredits', remainingCredits)

    // Trier les mois par ordre croissant (du plus ancien au plus récent)
    const sortedRemainingCredits = remainingCredits.sort((a: any, b: any) => {
      return new Date(a.month).getTime() - new Date(b.month).getTime();
    });

    // Trouver le mois le plus ancien qui a encore des crédits disponibles
    const availableMonth = sortedRemainingCredits.find((month: any) => month.credits > 0);

    if (!availableMonth) {
      enqueueSnackbar("Pas assez de crédits disponibles pour soumettre la demande", { variant: 'error' });
      setIsSubmitting(false);
      return;
    }

    const requestData = {
      metadata: { 
        user: { uid: currentUser.uid, email: currentUser.email }, 
        creation_date: now(), 
        date_fin_campagne: formattedEndDate,
        campagne_name: value.campaign_name,
        date_debut_campagne: formattedStartDate,
        uuid: uuidv4(),
        state: "SNCF_NEW_REQUEST",
        month_credit: availableMonth.month // Ajouter le mois avec crédit disponible
      }
    };

    try {
      await createRequest(requestData);
    } catch (error) {
      console.error("Erreur lors de l'envoi de la demande:", error);
    } finally {
      setIsSubmitting(false);
    }
  },
});

  const createRequest = async (value: any) => {
    const ciRequest = { ...value };
    const messages = {
      success: `Demande pour la campagne '${value.metadata.campagne_name}' créé avec succès`,
      error: `Une erreur est survenue lors de la création de la demande pour la campagne '${value.campaign_name}'`,
    };

    try {
      await runPostRequest(ciRequest, "/datastore/create/sncf", "/history", false, messages);

    } catch (error) {
      throw new Error("Erreur lors de la soumission de la demande");
    }
  };

  const handleStartDateChange = (newValue: Date | null) => {
    setStartDate(newValue);
    formik.setFieldValue('date_debut_campagne', newValue);
  };

  const handleEndDateChange = (newValue: Date | null) => {
    setEndDate(newValue);
    formik.setFieldValue('date_fin_campagne', newValue);
  };

  const maxDate = new Date();
  const minDate = subMonths(maxDate, 13);

  return (
    
<LocalizationProvider dateAdapter={AdapterDateFns}>
<form onSubmit={(e) => {
    console.log("Submit button clicked");  // Vérifiez si le bouton est bien cliqué
    formik.handleSubmit(e);
  }}>
    <Stack spacing={2} sx={{ padding: 2 }}>
      <Grid container spacing={3} alignItems="center" justifyContent="center"> {/* Réduction de spacing */}
        
        {/* Campagne */}
        <Grid item xs={12} sm={4} sx={{ marginLeft: 4 }}> {/* Ajout de marge gauche */}
        <SelectCampaign
          multiple={false}
          label="Sélectionnez votre campagne"
          value={formik.values.campaign_name}
          onChange={(event: any, selectedCampaign: any) => {
            if (selectedCampaign) {
              // Si une campagne est sélectionnée, on met à jour la valeur
              formik.setFieldValue('campaign_name', selectedCampaign.name);
            } else {
              // Si la sélection est supprimée, on réinitialise la valeur
              formik.setFieldValue('campaign_name', '');
            }
          }}
          error={Boolean(formik.errors.campaign_name && formik.touched.campaign_name)}
          helperText={formik.touched.campaign_name && formik.errors.campaign_name}
        />
        
        </Grid>

        {/* Date de début */}
        <Grid item xs={12} sm={3}>
          <DatePicker
            label="Date de début"
            value={startDate}
            onChange={handleStartDateChange}
            renderInput={(params) => <TextField {...params} fullWidth />}
            minDate={minDate}
            maxDate={maxDate}
            inputFormat="yyyy/MM/dd"
          />
          
        </Grid>

        {/* Date de fin */}
        <Grid item xs={12} sm={3} sx={{ marginRight: 4 }}> {/* Ajout de marge droite */}
          <DatePicker
            label="Date de fin"
            value={endDate}
            onChange={handleEndDateChange}
            renderInput={(params) => <TextField {...params} fullWidth />}
            minDate={minDate}
            maxDate={maxDate}
            inputFormat="yyyy/MM/dd"
          />
          
        </Grid>

        <Grid item xs={12} sm={4} sx={{ marginLeft: 4 }}>
          {formik.errors.campaign_name && <div style={{ color: 'red' }}>{formik.errors.campaign_name}</div>}
        </Grid>
        <Grid item xs={12} sm={3}>
          {formik.errors.date_debut_campagne && <div style={{ color: 'red' }}>{formik.errors.date_debut_campagne}</div>}
        </Grid>
        <Grid item xs={12} sm={3} sx={{ marginRight: 4 }}>
          {formik.errors.date_fin_campagne && <div style={{ color: 'red' }}>{formik.errors.date_fin_campagne}</div>}
        </Grid>
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <div style={{ marginTop: '60px' }} /> {/* Ajoute 30px de marge entre les sections */}

          </Grid>
        </Grid>

        {/* Boutons */}
        <Grid item xs={12} sm={5} sx={{ marginLeft: 4 }}> {/* Ajout de marge gauche */}
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<HistoryIcon />}
            onClick={() => navigate('/history')}
            fullWidth
          >
            Historique des demandes
          </Button>
        </Grid>

        <Grid item xs={12} sm={5} sx={{ marginRight: 4 }}> {/* Ajout de marge droite */}
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isSubmitting}
            style={{ color: "white" }}
            fullWidth
          >
            Envoi de la demande
          </Button>
        </Grid>
      </Grid>
    </Stack>
  </form>
</LocalizationProvider>
  );
}

export default Homepage;
