import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext"; // Assurez-vous que ce chemin est correct
import Grid from "@mui/material/Grid";

interface Credit {
    month: string;  // Format "YYYY-MM"
    credits: number;
}

const CreditHistory = () => {
  const { currentUser } = useAuth();
  const [creditsList, setCreditsList] = useState<Credit[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isMounted = true; // Flag pour vérifier si le composant est monté

    const fetchCredits = async () => {
      try {
        const idToken = await currentUser.getIdToken(true);
        const response = await fetch("/getCredits/", {
          method: "GET",
          headers: {
            Authorization: idToken,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          const errorMessage = await response.text(); // Récupération du message d'erreur
          throw new Error(`Erreur lors de la récupération des crédits : ${errorMessage}`);
        }

        const responseData = await response.json();
        if (isMounted) {
          setCreditsList(responseData); // Mettre à jour la liste des crédits
          setLoading(false); // Mettre à jour l'état de chargement
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des crédits :", error);
      }
    };

    fetchCredits();

    return () => {
      isMounted = false; // Mettre le flag à false lors du démontage
    };
  }, []); // Ajoute des dépendances si nécessaire

  if (loading) {
    return <div>Loading...</div>; // Affiche un indicateur de chargement
  }

  // Fonction pour convertir "2024-08" en "Août 2024"
  const formatMonth = (monthString: string) => {
    const [year, month] = monthString.split("-");
    const date = new Date(parseInt(year), parseInt(month) - 1); // Le mois commence à 0 en JS
    return date.toLocaleDateString("fr-FR", { year: "numeric", month: "long" });
  };

  return (
    <Grid item xs={14}>
        <h3  style={{ textAlign: "left", paddingLeft: "15%"  }}>Historique des Crédits</h3>
        <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
            {creditsList.map((credit, index) => (
            <li 
                key={index} 
                style={{ 
                display: "grid", 
                gridTemplateColumns: "1fr auto 1fr", 
                alignItems: "center", 
                marginBottom: "0px",
                textAlign: "center" 
                }}
            >
                <span style={{ textAlign: "right", paddingRight: "10px" }}>{formatMonth(credit.month)}</span>
                <span>:</span>
                <span style={{ textAlign: "left", paddingLeft: "10px" }}>{credit.credits}</span>
            </li>
            ))}
        </ul>
    </Grid>
  );
};

export default CreditHistory;