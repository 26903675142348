import { useState } from "react";
import { useNavigate } from "react-router-dom";
// import { useSnackbar } from "notistack";
import SignIn from "./SignIn";
import EmailResetPwd from "./EmailResetPwd";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import { ThemeProvider } from "@mui/material/styles";
import { useTheme } from "@mui/styles";

export default function Login() {
  const theme = useTheme();

  const [resettingPwd, setResettingPwd] = useState(false); // indicates if user is currently on the reset
  // const navigate = useNavigate();
  // const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        {resettingPwd && <EmailResetPwd resettingPwd={resettingPwd} setResettingPwd={setResettingPwd}/>}
        {!resettingPwd && <SignIn resettingPwd={resettingPwd} setResettingPwd={setResettingPwd}/>}
      </Container>
    </ThemeProvider>
  );
}
