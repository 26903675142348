import { useState, useEffect } from "react";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";

import { useAuth } from "../../contexts/AuthContext";
import Tooltip from "@mui/material/Tooltip";


const renderStateIcon = (params: any) => {
  return (
    <>
      {params.row.metadata.state === "SNCF_NEW_REQUEST" && (
        <Tooltip title="This CI request is currently being processed">
          <img src='/static/sablier.png' alt="Logo 1" style={{ width: '35px', height: '35px' }} />
        </Tooltip>
      )}
      {params.row.metadata.state === "SNCF_RUNNING" && (
        <Tooltip title="CI is currently running">
          <img src='/static/sablier.png' alt="Logo 1" style={{ width: '35px', height: '35px' }} />
        </Tooltip>
      )}
   
      
      {params.row.metadata.state === "SNCF_DONE" && (
        <Tooltip title="CI is currently DONE">
          <img src='/static/case-a-cocher.png' alt="Logo 1"  />
        </Tooltip>
      )}

     
      {params.row.metadata.state === "SNCF_JOB_ERROR" && (
        <Tooltip title="CI An error occured">
          <img src='/static/nop.png' alt="Logo 1" style={{ width: '35px', height: '35px' }}  />
        </Tooltip>
      )}
    
    </>
  );
};




const renderDashIcon = (params: any) => {
  return (
    <>
      {params.row.metadata.state === "SNCF_DONE" &&
        "dash_url" in params.row.metadata && (
          <Link href={params.row.metadata.dash_url} target="_blank">
            <Tooltip title="Dashboard">
              <IconButton>
                <img src='/static/dashboard.png' alt="Logo 1"  style={{ width: '30px', height: '30px' }}/>
              </IconButton>
            </Tooltip>
          </Link>
        )}
      {params.row.metadata.state !== "SNCF_DONE" && (
        <Link href={params.row.metadata.dash_url} target="_blank">
          <Tooltip title="Dashboard not available">
            <IconButton disabled={true}>

              {/* <img src='/static/dashboard-monitor-no.png' alt="Logo 1"  style={{ width: '35px', height: '35px' }}/> */}
              
            </IconButton>
          </Tooltip>
        </Link>
      )}
    </>
  );
};

function CiHistory(props: any) {
  const { currentUser } = useAuth();
  const [requestList, setRequestList] = useState([]);
  const [historyLoading, setHistoryLoading] = useState(true);
  const [needReload, setNeedReload] = useState(true);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Requête ID",
      editable: false,
      align: "center",
      headerAlign: "center",
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.metadata.uuid}`,
      flex: 1,
    },
    

    

    {
      field: "campagne_name",
      headerName: "Campagne",
      editable: false,
      flex: 0.8,
      align: "center",
      headerAlign: "center",
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.metadata.campagne_name}`,
    },
    
    {
      field: "date_debut_campagne",
      headerName: "Date de début",
      editable: false,
      flex: 0.4,
      align: "center",
      headerAlign: "center",
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.metadata.date_debut_campagne}`,
    },
    {
      field: "date_fin_campagne",
      headerName: "Date de fin",
      editable: false,
      flex: 0.4,
      align: "center",
      headerAlign: "center",
      valueGetter: (params: GridValueGetterParams) => 
        params.row.metadata.date_fin_campagne
     
    },
    {
      field: "creation_date",
      headerName: "Date de création",
      editable: false,
      flex: 0.7,
      align: "center",
      headerAlign: "center",
      valueGetter: (params: GridValueGetterParams) => 
        params.row.metadata.creation_date
     
    },
    {
      field: "email",
      headerName: "Créateur",
      editable: false,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.metadata.user.email}`,
    },


    {
      field: "dash_url",
      headerName: "Lien du rapport",
      editable: false,
      flex: 0.3,
      align: "center",
      headerAlign: "center",
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.metadata.dash_url}`,
      renderCell: renderDashIcon,
    },


    {
      field: "state",
      headerName: "Statut",
      editable: false,
      flex: 0.2,
      align: "center",
      headerAlign: "center",
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.metadata.state}`,
      renderCell: renderStateIcon,
    },

    // {
    //   field: "credit_month",
    //   headerName: "credit_month",
    //   editable: false,
    //   flex: 1,
    //   align: "center",
    //   headerAlign: "center",
    //   valueGetter: (params: GridValueGetterParams) =>
    //     `${params.row.metadata.month_credit}`,
    // },
    
    

    
  ];

  useEffect(() => {
    let isMounted = true; // Flag qui indique si le composant est monté
  
    const initialize = () => {
      currentUser.getIdToken(true).then(function (idToken: any) {
        fetch("/datastore/get/requests/all/sncf", {
          method: "post",
          headers: {
            Authorization: idToken,
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())
          .then((responseData) => {
            if (isMounted) { // Vérifiez si le composant est toujours monté
              setRequestList(responseData.requests);
              setHistoryLoading(false);
            }
          });
      });
    };
  
    initialize();
  
    return () => {
      isMounted = false; // Mettez le flag à false lors du démontage
    };
  }, [needReload]);

  return (
    <Grid container alignItems="center" justifyContent="center">
      <Grid item xs={10}>
        {!historyLoading && (
          <div style={{ height: 650, width: "100%" }}>
            <DataGrid
              rows={requestList}
              columns={columns}
              pageSize={10}
              disableSelectionOnClick
              getRowId={(row) => row.metadata.uuid}
              initialState={{
                sorting: {
                  sortModel: [{ field: "creation_date", sort: "desc" }],
                },
              }}
            />
          </div>
        )}

        {historyLoading && (
          <Grid item xs={10} alignContent="center">
            <CircularProgress />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default CiHistory;
