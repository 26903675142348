import DenseAppBar from "./components/ui/AppBar";
import { Outlet } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { SnackbarProvider } from "notistack";
import Slide from "@mui/material/Slide";
import React from "react";

const theme = createTheme({
  palette: {
    primary: { main: "#4EB6C5" },
    secondary: { main: "#AB003C" },
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          color: "#ffffff",
        },
      },
    },
  },
});

const notistackRef: any = React.createRef();
const onClickDismiss = (key: any) => () => {
  notistackRef.current.closeSnackbar(key);
};

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={1}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        TransitionComponent={Slide}
        ref={notistackRef}
        action={(key) => (
          <IconButton color="secondary" onClick={onClickDismiss(key)}>
            <CloseIcon fontSize="small" />
          </IconButton>
        )}
      >
        <CssBaseline />

        <div>
          <DenseAppBar />

          <Outlet />
        </div>

      </SnackbarProvider>
    </ThemeProvider>
  );
}
