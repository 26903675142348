import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/database";

// Firebase configuration
const firebaseConfig: any = {
  apiKey: "AIzaSyAPJ8yWUVgvcgAqNpKBX9U4KgPZBzYN5hY",
  authDomain: "datamining-1184.firebaseapp.com",
  databaseURL:
    "https://datamining-1184-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "datamining-1184",
  storageBucket: "datamining-1184.appspot.com",
  messagingSenderId: "162542643484",
  appId: "1:162542643484:web:a417e457287d4c23c632d9",
};

// Initialize Firebase app
const app = firebase.initializeApp(firebaseConfig);
export const auth = app.auth();
auth.setPersistence('session'); // Optional, keeps the session alive during the session

// Function to check if the user has access to the SNCF app
export const checkSNCFAccess = async (): Promise<boolean> => {
  const user = firebase.auth().currentUser;

  if (user) {
    try {
      // Get the user's ID token and its associated custom claims
      const idTokenResult = await user.getIdTokenResult();
      const allowedApps = idTokenResult.claims.allowedApps || [];
      console.log("firebase",allowedApps)
      // Check if 'SNCF' is part of the allowedApps
      if (allowedApps.includes('SNCF App')) {
        console.log('User has access to the SNCF app.');
        return true;
      } else {
        console.log('User does NOT have access to the SNCF app.');
        return false;
      }
    } catch (error) {
      console.error('Error retrieving custom claims:', error);
      return false;
    }
  } else {
    console.log('No user is signed in.');
    return false;
  }
};

// Function to handle user login and access check
export const loginAndCheckAccess = async (email: string, password: string) => {
  try {
    // Log in the user with email and password
    await auth.signInWithEmailAndPassword(email, password);

    // Check if the user has access to the SNCF app
    const hasAccess = await checkSNCFAccess();

    if (hasAccess) {
      console.log('Access granted: User can use the SNCF app.');
      // Proceed with app functionality
    } else {
      console.log('Access denied: User cannot use the SNCF app.');
      // Optionally, sign out the user or show an error message
      await auth.signOut();
      alert('You do not have access to the SNCF app.');
    }
  } catch (error) {
    console.error('Error during login:', error);
    alert('Login failed. Please check your credentials.');
  }
};

export default app;


