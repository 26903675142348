import { useAuth } from "../../contexts/AuthContext";
import Grid from "@mui/material/Grid";
import CiHistory from "./CiHistory";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import CreditHistory from "./CreditHistory";


function History(props: any) {
  const { currentUser } = useAuth();
  const navigate = useNavigate();


  const handleGoHome = () => {
    navigate("/home");
  };

  return (
    <Grid container alignItems="center" justifyContent="center" spacing={2.5}>


      <Grid item xs={9} /> 
      <Grid item xs={3}>
        <CreditHistory />
      </Grid>

      <Grid item xs={12}>
        <div>
          <CiHistory />
        </div>
      </Grid>

      <Grid item xs={10}>
        <Button
          onClick={handleGoHome}
          style={{
            backgroundColor: '#4EB6C5', // Fond transparent
            color: "white",      // Texte en blanc
          }}
        >
         Retour à la page d'accueil
        </Button>
      </Grid>

      <Grid item xs={10}/>

    </Grid>
  );
}

export default History;
