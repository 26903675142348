import { Link } from "react-router-dom";
import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/styles";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    textDecoration: "none",
    boxShadow: "none",
    color: "#ffffff",
  },
  logo: {
    height: "40px", // Adjust the size of the logos
    marginRight: "10px", // Add space between logos
    marginLeft: "10px",
  },
  collaboration: {
    color: "#ffffff", // White color for the "X"
    fontSize: "28px", // Size of the "X"
    margin: "0 10px", // Space around the "X"
    fontWeight: 9000, // Bold the "X" for emphasis
    textDecoration: "none", // Remove underline from the "X"
  },
});

export default function DenseAppBar() {
  const [selectedTab, setSelectedTab] = useState(-1);
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const classes = useStyles();

  async function handleLogout() {
    try {
      await logout();
      navigate("/login");
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="sticky">
        <Toolbar variant="dense" sx={{ minHeight: 70 }}> {/* Increase the height here */}
          <Grid
            container
            spacing={4}
            alignItems="center"
            justifyContent="space-between"
            direction="row"
          >
            <Grid item xs={3} container alignItems="center">
              <Link to="/" style={{ textDecoration: 'none' }}>
                <Box display="flex" alignItems="center">
                  <img src='/static/last_webo_logo.png' alt="Logo 1" className={classes.logo} />
                  <Typography className={classes.collaboration}>x</Typography>
                  <img src='/static/Logo_SNCF_Connect.png' alt="Logo 2" className={classes.logo} />
                </Box>
              </Link>
            </Grid>

            <Grid item xs={7}>
              <Tabs
                value={selectedTab}
                onChange={(e: any, newValue: any) => {
                  setSelectedTab(newValue);
                }}
                centered
                indicatorColor="secondary"
                textColor="secondary"
                variant="scrollable"
                scrollButtons={true}
                allowScrollButtonsMobile
              >
                {/* Add Tab elements here */}
              </Tabs>
            </Grid>

            <Grid item xs={2} container justifyContent="flex-end">
              {currentUser && (
                <Button variant="contained" onClick={handleLogout}>
                  <Typography
                    className={classes.root}
                    component={Link}
                    style={{ fontSize: '17px' }}
                    to="/">
                    Déconnexion
                  </Typography>
                </Button>
              )}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  );
}