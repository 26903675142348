import React, { useState, useEffect } from "react";


import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";


import { useAuth } from "../../contexts/AuthContext";


export const SelectCampaign = (props: any) => {
  const [campaignList, setCampaignList] = useState([]);
  const { currentUser } = useAuth();

  useEffect(() => {
    const initialize = () => {
      currentUser.getIdToken(true).then(function (idToken: any) {
        fetch("/field/campaign" , {
          method: "get",
          headers: { Authorization: idToken },
        })
          .then((response) => response.json())
          .then((responseData) => {

            console.log(responseData)
            setCampaignList(responseData.campaign.sort((a: any, b: any) => {

              if (a.id > b.id) {
                return -1;
              }
              if (a.id < b.id) {
                return 1;
              }
              return 0;
            }));


          });
      });
    };
    return initialize();
  }, []);

  const getLabel = (option: any) => {
    if(option.id){
      return option.id + " - " + option.name;
    }else{
      return option.id
    }
    
  };




  return (
    <Autocomplete
      multiple={props.multiple}
      id="campagne"
      options={campaignList}
      getOptionLabel={getLabel}
      filterSelectedOptions
      onChange={props.onChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Selectionnez votre campagne"
          placeholder="cliquez pour la liste des campagnes"
        />
      )}
    />
  );
};

